import { apiSlice } from "../apiSlice";

export const tableApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDataTracking: builder.query({
      query: (parameters) => ({
        url: `/${parameters.URL}`,
        params: `filters[REGESTXX]=ACTIVO&page=${parameters.page}&per_page=${parameters.per_page}&sorts=${parameters.sorts}&${parameters.stringFilters}`,
      }),
      providesTags: ["reloadTable"],
    }),
    deleteCompanyAreaSerieTypeMetadata: builder.mutation({
      query: (parameters) => ({
        url:
          `tipometadatos/` +
          parameters.EMPNITXX +
          "/" +
          parameters.AREAIDXX +
          "/" +
          parameters.SERIEIDX +
          "/" +
          parameters.TIPOIDXX +
          "/" +
          parameters.METIDXXX,
        method: "DELETE",
      }),
      invalidatesTags: ["reloadTable"],
    }),
    deleteChargesUserAreaSeriesType: builder.mutation({
      query: (parameters) => ({
        url: parameters.URLXXXXX + "/" + parameters.IDKEYXXX + "/" + parameters.EMPNITXX,
        method: "PUT",
      }),
      invalidatesTags: ["reloadTable"],
    }),
    updateChargesUserAreaSeriesType: builder.mutation({
      query: (parameters) => ({
        url: `${parameters.URLXXXXX}/${parameters.IDKEYXXX}/${parameters.EMPNITXX}`,
        method: "PUT",
        body: {
          EDITXXXX: parameters.EDITXXXX,
          DELETEXX: parameters.DELETEXX,
        },
      }),
      invalidatesTags: ["reloadTable"],
    }),
  }),
});

export const { useGetDataTrackingQuery } = tableApiSlice;
export const { useDeleteCompanyAreaSerieTypeMetadataMutation } = tableApiSlice;
export const { useDeleteChargesUserAreaSeriesTypeMutation } = tableApiSlice;
export const { useUpdateChargesUserAreaSeriesTypeMutation } = tableApiSlice;
