import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import { columnsTracking, buttons } from "./configxx";
import Tablexxx from "../Tablexxx";
import Creserxx from "../../components/Creserxx";
import { useGetCompanysQuery, useLazyGetSeriesByAreaQuery, useGetSerieQuery, useGetProcesoQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import { useDispatch } from "react-redux";
import { setFilters } from "../../libs/redux/slices/filterSlice/filterSlice";

const Tabsserx = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [component, setComponent] = useState("");
  const [row, setRow] = useState({});
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSerie, setSelectedSerie] = useState("");

  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [areasOptions, setAreasOptions] = useState([]);
  const [seriesOptions, setSeriesOptions] = useState([]);
  const [trigger, setTrigger] = useState(false);

  const { data: companies, isLoading: isLoadingCompanies } = useGetCompanysQuery();
  const { data: series, isLoading: isLoadingSeries } = useGetSerieQuery();
  const { data: globalProcesos, isLoading: isLoadingGlobalProcesos } = useGetProcesoQuery();

  const [triggerGetSerieEmpresa, { isLoading: isLoadingAreasSeries }] = useLazyGetSeriesByAreaQuery();

  useEffect(() => {
    if (companies?.data) {
      setCompaniesOptions(
        Array.from(new Set(companies.data.map(company => company.EMPNITXX)))
          .map(value => {
            const company = companies.data.find(c => c.EMPNITXX === value);
            return { label: company.EMPNOMXX, value: company.EMPNITXX };
          })
      );
    }
  }, [companies, trigger]);

  useEffect(() => {
    if (globalProcesos?.data) {
      setAreasOptions(
        Array.from(new Set(globalProcesos.data.map(proceso => proceso.AREAIDXX)))
          .map(value => {
            const proceso = globalProcesos.data.find(p => p.AREAIDXX === value);
            return { label: proceso.AREADESX, value: proceso.AREAIDXX };
          })
      );
    }
  }, [globalProcesos, trigger]);

  useEffect(() => {
    if (series?.data) {
      setSeriesOptions(
        Array.from(new Set(series.data.map(serie => serie.SERIEIDX)))
          .map(value => {
            const serie = series.data.find(s => s.SERIEIDX === value);
            return { label: serie.SERIEDES, value: serie.SERIEIDX };
          })
      );
    }
  }, [series, trigger]);

  useEffect(() => {
    if (selectedCompany || selectedArea || selectedSerie) {
      const EMPNITXX = selectedCompany || "null";
      const AREAIDXX = selectedArea || "null";
      const SERIEIDX = selectedSerie || "null";

      triggerGetSerieEmpresa({ EMPNITXX, AREAIDXX, SERIEIDX })
        .then((response) => {
          if (response?.data) {
            const dataArray = Object.values(response.data);

            if (selectedCompany && !selectedArea) {
              setAreasOptions(
                Array.from(new Set(dataArray[0].map(item => item.AREAIDXX)))
                  .map(value => {
                    const area = dataArray[0].find(a => a.AREAIDXX === value);
                    return { label: area.AREADESX, value: area.AREAIDXX };
                  })
              );
            }

            if (selectedArea && !selectedSerie) {
              setSeriesOptions(
                Array.from(new Set(dataArray[0].map(item => item.SERIEIDX)))
                  .map(value => {
                    const serie = dataArray[0].find(s => s.SERIEIDX === value);
                    return { label: serie.SERIEDES, value: serie.SERIEIDX };
                  })
              );
            }
          }
        })
        .catch(error => {
          console.error('Error al obtener datos:', error);
        });
    }
  }, [selectedCompany, selectedArea, selectedSerie, triggerGetSerieEmpresa]);

  const handleCompanyChange = (e) => {
    setSelectedCompany(e.target.value);
    setSelectedArea("");  // Limpiar los selects dependientes
    setSelectedSerie("");
  };

  const handleAreaChange = (e) => {
    setSelectedArea(e.target.value);
    setSelectedSerie("");  // Limpiar el select dependiente
  };

  const handleSerieChange = (e) => {
    setSelectedSerie(e.target.value);
  };

  const handleFilter = () => {
    let filters = "filters[REGESTXX]=ACTIVO";
    if (selectedCompany) {
      filters += `&filters[EMPNITXX]=${selectedCompany}`;
    }
    if (selectedArea) {
      filters += `&filters[AREAIDXX]=${selectedArea}`;
    }
    if (selectedSerie) {
      filters += `&filters[SERIEIDX]=${selectedSerie}`;
    }
    dispatch(setFilters(filters));
  };

  const handleClear = () => {
    setSelectedCompany("");
    setSelectedArea("");
    setSelectedSerie("");
    dispatch(setFilters(""));
    setTrigger(prevTrigger => !prevTrigger);
  };

  let oComponent;
  let tittle;
  switch (component) {
    case "EDITAR":
      oComponent = <Creserxx onClose={setShow} isCreating={false} row={row} isEdit={false}/>;
      tittle = "EDITAR SERIE";
      break;
    case "VISUALIZAR":
      oComponent = <Creserxx onClose={setShow} isCreating={false} row={row} isEdit={true}/>;
      tittle = "VER SERIE";
      break;
    case "CREAR":
      oComponent = <Creserxx onClose={setShow} isCreating={true} row={row} isEdit={false}/>;
      tittle = "CREAR SERIE";
      break;
    default:
      break;
  }

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} className="modal-xl">
        <Modal.Header closeButton>
          <Modal.Title>{tittle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{oComponent}</Modal.Body>
      </Modal>

      <Row className="mb-4">
        <Col md={4}>
          <Button
            className="w-100"
            onClick={() => { setShow(true); setComponent("CREAR"); }}
          >
            Crear Serie
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Group controlId="formEmpresa">
            <Form.Label>Empresa:</Form.Label>
            {isLoadingCompanies ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedCompany}
                onChange={handleCompanyChange}
              >
                <option value="">[SELECCIONE]</option>
                {companiesOptions.map((company) => (
                  <option key={company.value} value={company.value}>
                    {company.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formProceso">
            <Form.Label>Proceso:</Form.Label>
            {isLoadingAreasSeries || isLoadingGlobalProcesos ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedArea}
                onChange={handleAreaChange}
              >
                <option value="">[SELECCIONE]</option>
                {areasOptions.map((area) => (
                  <option key={area.value} value={area.value}>
                    {area.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group controlId="formSerie">
            <Form.Label>Serie:</Form.Label>
            {isLoadingAreasSeries || isLoadingSeries ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <Form.Control
                as="select"
                value={selectedSerie}
                onChange={handleSerieChange}
              >
                <option value="">[SELECCIONE]</option>
                {seriesOptions.map((serie) => (
                  <option key={serie.value} value={serie.value}>
                    {serie.label}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <Col md={2}>
          <Button className="w-100" onClick={handleFilter}>
            Filtrar
          </Button>
        </Col>
        <Col md={2}>
          <Button className="w-100" onClick={handleClear}>
            Limpiar
          </Button>
        </Col>
      </Row>
      <Tablexxx
        columns={columnsTracking}
        URL={"areasxseries"}
        buttons={buttons}
        setComponent={setComponent}
        setShow={setShow}
        setRow={setRow}
      />
    </>
  );
};

export default Tabsserx;
