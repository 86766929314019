import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetCompanysQuery } from "../../libs/redux/slices/filterSlice/filterApiSlice";
import {
  useCreateAreaMutation,
  useCreateCompanyAreasMutation,
  useGetCompanyAreasQuery,
  useUpdateCompanyAreaMutation,
} from "../../libs/redux/slices/crearexxSlice/crearexxApiSlice";
import Toast from "react-bootstrap/Toast";
import { setDictionaryErrors } from "../../libs/redux/slices/errorSlice/errorSlice";
import { dictionaryErrors } from "./configxx";
import { useDispatch } from "react-redux";
import { setFilters } from "../../libs/redux/slices/filterSlice/filterSlice";

const Crearexx = ({ onClose, isCreating, row, isEdit }) => {
  const dispatch = useDispatch();
  dispatch(setDictionaryErrors({ dictionaryErrors }));

  const { data: companys, isLoading: isLoadingCompanys } = useGetCompanysQuery();
  const [insertArea, { isLoading: isLoadingArea }] = useCreateAreaMutation();
  const [insertCompanyAreas, { isLoading: isLoadingCompanyAreas }] = useCreateCompanyAreasMutation();
  const [upCompanyAreas, { isLoading: isUpdatingCompanyAreas }] = useUpdateCompanyAreaMutation();

  const [validated, setValidated] = useState(false);
  const [descriptionArea, setDescriptionArea] = useState("");
  const [descriptionPath, setDescriptionPath] = useState("");
  const [nitsCompanys, setNitsCompanys] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [reload, setReload] = useState(false);
  const [companiesValid, setCompaniesValid] = useState(true);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  // Hook para cargar empresas relacionadas al area
  const { data: companyAreasData, isLoading: isLoadingCompanyAreasData } = useGetCompanyAreasQuery(row?.AREAIDXX, {
    skip: !row?.AREAIDXX,
  });

  // Efecto para inicializar los valores en modo edicion o visualizacion
  useEffect(() => {
    if (row) {
      setDescriptionArea(row.AREADESX || "");
      setDescriptionPath(row.AREAPATH || "");
    }
  }, [row]);

  // Efecto para agregar las empresas asociadas al area al array nitsCompanys
  useEffect(() => {
    if (companyAreasData?.data) {
      const newNits = companyAreasData.data.map((company) => company.EMPNITXX);
      setNitsCompanys(newNits);
    }
  }, [companyAreasData, reload]);

  useEffect(() => {
    if (isCreating) {
      setDescriptionArea("");
      setDescriptionPath("");
      setNitsCompanys([""]);
      setValidated(false);
      setCompaniesValid(true);
    }
  }, [isCreating]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() && nitsCompanys.length > 0) {
      if (isCreating) {
        // Logica cuando isCreating es true (Crear un area nueva)
        await insertArea({ AREADESX: descriptionArea, AREAPATH: descriptionPath })
          .unwrap()
          .then((response) => {
            saveCompanyAreas(response.AREAIDXX);
          })
          .catch((error) => {
            console.error("Error creating area:", error);
          });
      } else {
        // Logica cuando isCreating es false (Editar area existente)
        if (row && row.AREAIDXX) {
          await updateCompanyAreas(row.AREAIDXX, descriptionArea)
            .then(() => {
            })
            .catch((error) => {
              console.error("Error updating company areas:", error);
              setReload((prevReload) => !prevReload);
            });
        } else {
          console.error("No AREAIDXX provided for editing.");
        }
      }
    } else {
      if (nitsCompanys.length === 0) {
        setCompaniesValid(false);
      }
      event.preventDefault();
    }
    setValidated(true);
  };


  const saveCompanyAreas = async (areaID) => {
    await insertCompanyAreas({ EMPNITXX: nitsCompanys, AREAIDXX: areaID })
      .unwrap()
      .then(() => {
        onClose(false);
        setSavedSuccessfully(true);
      })
      .catch();
  };

  useEffect(() => {
    if (savedSuccessfully) {
      handleFilter();
      setSavedSuccessfully(false);
    }
    // eslint-disable-next-line
  }, [savedSuccessfully]);


  const handleFilter = () => {
    let filters = "filters[REGESTXX]=ACTIVO";
    dispatch(setFilters(filters));
  };

  const updateCompanyAreas = async (areaID, descriptionArea) => {
    await upCompanyAreas({ EMPNITXX: nitsCompanys, AREAIDXX: areaID, AREADESX: descriptionArea })
      .unwrap()
      .then(() => {
        onClose(false);
        setSavedSuccessfully(true);
      })
      .catch();
  };

  const handleSelectChange = (e, index) => {
    const selectedValue = e.target.value;
    if (selectedValue && !nitsCompanys.includes(selectedValue)) {
        const updatedNitsCompanys = [...nitsCompanys];
        updatedNitsCompanys[index] = selectedValue;
        setNitsCompanys(updatedNitsCompanys);
        setCompaniesValid(true);
    } else {
      setToastMessage("El valor de Empresa debe ser único.");
      setShowToast(true);
    }
  };

  const handleNewCompany = () => {
    setNitsCompanys([...nitsCompanys, [""]]);
  };
  const removeCapsule = (value) => {
    setNitsCompanys(nitsCompanys.filter((item) => item !== value));
    if (nitsCompanys.length === 1) {
      setCompaniesValid(false);
    }
  };

  return isLoadingArea || isLoadingCompanyAreas || isLoadingCompanyAreasData || isUpdatingCompanyAreas ? (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  ) : (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Nombre*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionArea}
              type="text"
              required
              onChange={(e) => setDescriptionArea(e.target.value)}
              disabled={isEdit}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar el Nombre del Area</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>

      <Row md={12} className="mt-3">
        <Form.Group as={Col} md={4}>
          <Form.Label>Descripcion del Path*</Form.Label>
          <InputGroup>
            <Form.Control
              value={descriptionPath}
              type="text"
              required
              onChange={(e) => setDescriptionPath(e.target.value)}
              minLength={8}
              maxLength={8}
              disabled={!isCreating}
            />
            <Form.Control.Feedback type="invalid">Debe Diligenciar la Descripcion del Path</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>

      {nitsCompanys.map((nit, index) => (
        <Row md={12} className="mt-4" key={index}>
          <Form.Group as={Col} md={5}>
            <Form.Label>Empresa:*</Form.Label>
            <InputGroup>
              <Form.Select
                value={nit}
                onChange={(e) => handleSelectChange(e, index)}
                required
                isInvalid={!companiesValid}
                disabled={isEdit}
              >
                <option value={""}>[SELECCIONE]</option>
                {isLoadingCompanys ? (
                  <option value={""}>CARGANDO...</option>
                ) : (
                  companys?.data
                    .filter(company =>
                      // Mostrar la empresa seleccionada actualmente o aquellas que aún no han sido seleccionadas
                      company.EMPNITXX === nit || !nitsCompanys.includes(company.EMPNITXX)
                    )
                    .map((company) => (
                      <option key={company.EMPNITXX} value={company.EMPNITXX}>
                        {company.EMPDESXX}
                      </option>
                    ))
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">Debe Seleccionar una Empresa</Form.Control.Feedback>
              {index === nitsCompanys.length - 1 && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ms-2 rounded-circle"
                  onClick={() => handleNewCompany()}
                  disabled={isEdit}
                  style={{ width: "30px", height: "30px", color: "white", backgroundColor: "#007bff", border: "none" }}
                >
                  +
                </Button>
              )}
              <Button
                variant="outline-secondary"
                size="sm"
                className="ms-2 rounded-circle bg-secondary text-white"
                onClick={() => removeCapsule(nit)}
                disabled={nitsCompanys.length === 1 || isEdit}
                style={{ width: "30px", height: "30px", border: "none" }}
              >
                ×
              </Button>
            </InputGroup>
          </Form.Group>
        </Row>
      ))}

      <Row md={12} className="mt-3">
        <Col className="offset-10" md={1}>
          {!isEdit && <Button type="Submit">Guardar</Button>}
        </Col>
        {isCreating && (
          <Col md={1}>
            <Button
              onClick={() => {
                setValidated(false);
                setDescriptionArea("");
                setDescriptionPath("");
                setNitsCompanys([""]);
                setCompaniesValid(true);
              }}
              disabled={isEdit}
            >
              Limpiar
            </Button>
          </Col>
        )}
      </Row>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
          zIndex: 9999,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">Notificación</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Form>
  );
};

export default Crearexx;
